import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { JobTypeService } from '../../services/job-type.service';
import { SearchMarketService } from '../../services/search-market.service';
import { lastValueFrom } from 'rxjs';
import { DropDown, SearchMarket } from '../../../modules/client/dashboard/settings/settings.model';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReviewerService } from '../../services/reviewer.service';
@Component({
    selector: 'app-update-user-data',
    standalone: true,
    templateUrl: './update-user-data.component.html',
    styleUrl: './update-user-data.component.scss',
    imports: [ButtonModule, FormsModule, MultiSelectModule, DropdownModule, FloatLabelModule],
})
export class UpdateUserDataComponent implements OnInit {

  firmId!: string;
  userId!: string;
  userName!: string;
  type!: string;
  dropdownData: any = [];
  jobType: DropDown[] = [];
  searchMarketValues: SearchMarket[] = [];
    
  constructor(private ref: DynamicDialogRef, 
    public config: DynamicDialogConfig, 
    private searchMarketService: SearchMarketService,
    private reviewerService: ReviewerService,
    private jobTypeService: JobTypeService,
  ) { }

  ngOnInit(): void {
    this.firmId = this.config.data.firmId;
    this.userId = this.config.data.userId;
    this.type = this.config.data.type;
    this.userName = this.config.data.userName;

    if(this.type === 'Search Market'){
      this.setSearchMarkets();
    }
    else if(this.type === 'Job Type'){
      this.setJobTypes();
    }
  }

  setSearchMarkets() {
    this.searchMarketService.getFirmSearchMarkets(this.firmId).subscribe((res) => {
      this.dropdownData = res.filter((item: any) => item.isActive);
      this.dropdownData = this.dropdownData.map((val: any) => {return {name: val.name, id: val._id}});
    });
  }

  setJobTypes() {
    this.jobTypeService.getFirmJobType(this.firmId).subscribe((res) => {
      this.dropdownData = res.filter((item: any) => !item.isArchived);
      this.dropdownData = this.dropdownData.map((val: any) => {return {name: val.name, id: val._id}});
    })
  }

  onChange($event: any): void {
    console.log(this.config.data.selectedItems);
  }

  closeDialog(){
    this.ref.close();
  }

  confirm() {
    let body = {
      userId: this.userId,
      profile: {}
    };
    let selectedIds = this.config.data.selectedItems.map((item: any) => item.id);
    if(this.type === 'Search Market'){
      body.profile = {
        searchMarkets: selectedIds
      }
      this.reviewerService.updateSearchMarkets(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
    else if(this.type === 'Job Type'){
      body.profile = {
        jobCategories: selectedIds
      }
      this.reviewerService.updateJobTypes(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
  }

}
