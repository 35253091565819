<div header="Update User Data" class="align-items-center">
    <div class="col-12 mb-3 p-0">
        <div class="w-full flex flex-column gap-3 pb-3">
            <div class="flex gap-1">
                Any changes will be visible to {{userName}}
            </div>            
        </div>
        <p-multiSelect [(ngModel)]="config.data.selectedItems"
            display="chip" (onChange)="onChange($event)"
            [options]="dropdownData" optionLabel="name" appendTo="body"/>
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button [disabled]="!config.data.selectedItems.length" label="OK" (click)="confirm()" />
    </div>
</div>