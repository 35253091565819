<app-heading [isbreadcrumb]="false" [title]="'Assign Talent pools for the Candidate'" [showToggle]="false" [button]="false"></app-heading>

<div class="sm:mb-4">
    <p-button (click)="goToCreateNewTalentPool()" [label]="'Create New Talent Pool'" [rounded]="true"
        icon="pi pi-plus" size="large" />
</div>

<div class="projects-data">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0 mb-3">
            <div class="grid grid-nogutter">
                <div class="col-12 md:col-6 xl:col-6 lg:col-6 sm:col-12 p-0">
                    <div class="wrap-left">
                       <div class="grid grid-nogutter">
                            <div class="md:pr-1 sm:pr-0 col-12 md:col-4 sm:col-12 ">
                                <p-iconField iconPosition="right">
                                    <p-inputIcon (click)="onSearchClick()"  styleClass="pi pi-search" />
                                    <input [(ngModel)]="searchText" (keydown)="onEnter($event)" type="text" pInputText placeholder="Search" />
                                </p-iconField>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <div class="data-table">
                <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="talentPoolsData" dataKey="_id"
                    [tableStyle]="{ 'min-width': '60rem' }" [expandedRowKeys]="expandedRows"
                    (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)" [lazy]="true"
                    [rows]="10" [paginator]="true" [totalRecords]="totalTalentPoolCount"
                    (onLazyLoad)="getTalentPools($event)" [showCurrentPageReport]="true" [paginator]="true"
                    currentPageReportTemplate="Showing {first} - {last} of {totalRecords}" autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                Name

                            </th>
                            <th>
                                No of Users

                            </th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" class="border-0" let-talentPoolsData let-expanded="expanded">
                        <tr>
                            <td><b>{{ talentPoolsData.name }}</b>
                            </td>
                            <td> {{ talentPoolsData.reviewers }}</td>
                         
                            <td>
                                <div class="wrap-actions flex align-items-center gap-2">

                                  
                                    <p-button (click)="assignTalentPoolToUser(talentPoolsData)" [rounded]="true" [text]="true"
                                    icon="true">
                                    <span class="material-symbols-outlined">
                                        add
                                    </span>
                                </p-button>

                                  
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                  
            </p-table>
        </div>
    </div>
    <div class="flex justify-content-start">
        <p-button (onClick)="backToProfile()" label="BACK" size="large" [rounded]="true" />
    </div>
</div>

</div>